<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
        <path
            fill-rule="evenodd"
            d="M5.36958843 17.6764405c-.60971888 0-1.12732196.2107736-1.55390586.6301588-.42658389.4204662-.63932753.9306462-.63932753 1.531621 0 .6009747.21274364 1.1111547.63932753 1.5316209C4.24226647 21.7903074 4.75986955 22 5.36958843 22c.60971889 0 1.12732197-.2096926 1.55390587-.6301588.42658389-.4204662.63932753-.9306462.63932753-1.5316209 0-.6009748-.21274364-1.1111548-.63932753-1.531621-.4265839-.4193852-.94418698-.6301588-1.55390587-.6301588m13.18747377 0c-.6086223 0-1.127322.2107736-1.5539059.6301588-.4265838.4204662-.6393275.9306462-.6393275 1.531621 0 .6009747.2127437 1.1111547.6393275 1.5316209.4265839.4204662.9452836.6301588 1.5539059.6301588.6097189 0 1.127322-.2096926 1.5539059-.6301588s.6393275-.9306462.6393275-1.5316209c0-.6009748-.2127436-1.1111548-.6393275-1.531621-.4265839-.4193852-.944187-.6301588-1.5539059-.6301588m1.7980128-8.57859049L6.11112065 10.6727065 4.38614258 4.55378901H20.355075v4.544061ZM3.92885342 2.93245421 3.10200442 0h-4.10244307v1.6213348H1.8518614l4.09476675 14.5228363H20.9033833v-1.6213348H7.19677118l-.63932754-2.267707L22 10.5473233V2.93245421H3.92885342Z"
        />
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
